<template>
  <v-app>
    <v-main
      style="background-color:#eee;background:url('/img/bg.png') repeat;background-position:center;background-size:cover;position: fixed;height: 100vh;width: 100vw;overflow: visible;z-index: 100;margin:0 auto;"
    >
      <v-container class="d-flex align-center justify-center" fill-height fluid>
        <div v-if="xs">
          <v-img
            width="265"
            height="88"
            src="/img/logo.png"
            style="margin:0 auto;margin-top:90px;"
          >
          </v-img
          ><br />
          <h3 style="color:white;">SITE EM DESENVOLVIMENTO</h3>
          <div class="text-center">
            <v-btn href="https://www.mrxweb.com.br/" target="_blank" text>
              <span style="color:white;">www.mrxweb.com.br</span>
            </v-btn>
          </div>
        </div>

        <v-card v-if="!xs" elevation="6" max-width="550">
          <v-img height="250" src="/img/bg.png">
            <a href="https://www.mrxweb.com.br/">
              <v-img
                width="265"
                height="88"
                src="/img/logo.png"
                style="margin:0 auto;margin-top:90px;"
              >
              </v-img>
            </a>
          </v-img>

          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title
                class="text-xl-h4"
                style="color:black;font-weight:bold;font-size:22pt;text-align:center;margin-top:10px;"
              >
                SITE EM DESENVOLVIMENTO
              </v-list-item-title>
              <v-list-item-subtitle
                class="text-md-body-1"
                style="color:black;text-align:center;font-size:12pt;margin-top:10px;"
              >
                Em breve esta marca estará representada na web, aguarde!
              </v-list-item-subtitle>
              <div class="d-flex flex-column justify-center align-center">
                <v-btn
                  href="https://www.mrxweb.com.br/"
                  target="_blank"
                  text
                  style="margin-top:15px;"
                >
                  <span>www.mrxweb.com.br</span>
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  computed: {
    xs() {
      return this.$vuetify.breakpoint.xsOnly;
    }
  }
};
</script>

<style>
@import "./style/fonts.css";
@import "./style/main.css";
@import "./style/custom-user.css";
@import "./style/custom-vuetify.css";
</style>